<template>
  <RequestAbstract></RequestAbstract>
</template>

<script>
import RequestAbstract from '../../components/Request/Abstract'

export default {
  components: {
    RequestAbstract,
  },
};
</script>

<style>
</style>
