<template>
    <v-timeline align-top dense>
      <v-timeline-item v-for="(item, i) in requestAnalysisList" :key=i  small>
        <v-row class="pt-1">
			<v-col cols="3">
				<div class="font-weight-bold"> {{ item.data_cadastro }}</div>
				<span class="text-caption"
				> {{item.situacao_text }}</span>
			</v-col>
			<v-col cols="2">
				<v-btn v-if="item.situacao_text === 'Laudo Concluído'" small icon @click="getUrl(item)">
					<v-icon>mdi-file</v-icon>
				</v-btn>
			</v-col>
        </v-row>
      </v-timeline-item>
    </v-timeline>
  </template>

<script>
import { mapGetters } from 'vuex';


export default {
	name: 'AnalysisCard',

	computed: {
			...mapGetters([
			'getAnalysisList',
			'isGSuser',
     		 'inAnalysis',
			]),
			requestAnalysisList() {
				return this.getAnalysisList
			}
	},
	methods: {
		requestReload(){
			this.$emit('reload')
		},

		getUrl(item){
			this.$emit('getUrl', item)
		}
	}
}

</script>