<template >
  <div class="d-flex align-center">
    <v-row v-if="isGSuser && inAnalysis" class="mr-4">
      <v-btn rounded color="primary" small class="mt-3 mr-10" @click="requestReload">
        Atualizar
        <v-icon>mdi-reload</v-icon>
      </v-btn>
      <confirm-analysis-dialog :dialog="dialog" @close="closeDialog" @confirm="create" ></confirm-analysis-dialog>
      <v-btn rounded color="alert" small class="mt-3 mr-10 white--text" @click="verifyNewAnalysis">
        Nova análise
        <v-icon>mdi-plus</v-icon>
      </v-btn>
  </v-row>
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import ConfirmAnalysisDialog from './ConfirmAnalysisDialog.vue'

function initialState() {
  return {
    dialog: false,
    loading: false
  }
}

export default {
  name: 'RequestNewAnalysis',
  components: {
    ConfirmAnalysisDialog
  },
  props: {
    pendencieId: {
      type: Number,
      required: false
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return initialState()
  },
  computed: {
    ...mapGetters([
      'getAnalysisList',
      'isGSuser',
      'inAnalysis',
      'requiresNewAnalysis',
    ])
  },
  created() {
    this.unwatch = this.$store.watch(
      (state, getters) => getters.requiresNewAnalysis,
      (newValue, oldValue) => {
        if(newValue.processed) {
          if(newValue.value) {
            this.openDialog();
            return;
          }
          this.create();
        }
      },
    );  
  },
  beforeDestroy() {
    this.unwatch();
  },
  methods: {
    ...mapActions([
      'savePendency',
      'verifyRequiresNewAnalysis',
      'resetRequiresNewAnalysis'
    ]),
    verifyNewAnalysis() {
      this.verifyRequiresNewAnalysis(this.$route.params.id);
    },

    create() {
      this.closeDialog();
      this.$emit('create')
    },
    openDialog() {
      this.dialog=true;
    },
    requestReload() {
      this.$emit("reload")
    },
    closeDialog() {
      this.dialog = false
      this.resetRequiresNewAnalysis();
    },
  }
}
</script>
