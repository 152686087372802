<template>
  <v-dialog v-model="showDialog" max-width="600px">
    <v-card>
      <v-card-text class="pl-2 pr-2 pt-1 pb-1">
        <v-container>
          <v-row>
            <v-col class="pa-0 ma-0">
              <h5 class="bold">A nova análise irá gerar custos. Deseja prosseguir?</h5>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click=close
          rounded
          width="120px"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="error"
          @click=send
          rounded
          :loading=loading
        >
          Continuar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'AnalysisConfirmDialog',
  props: {
    dialog: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    loading: false
  }),
  computed: {
    showDialog () {
      return this.dialog
    }
  },
  methods: {
    send () {
      this.loading = true
      try {
        this.$emit('confirm')
      } catch {
        this.loading = false
      }
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>
