<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <v-card>
      <v-col cols="12" class="d-flex justify-space-between align-center pa-0">
        <v-card-title> Download de Documentos </v-card-title>
        <v-btn icon @click="close"> <v-icon> mdi-close </v-icon></v-btn>
      </v-col>
      <div class="ml-4">
        <v-col
          v-for="(item, index) in generatedDocuments"
          :key="index"
          cols="12"
        >
          {{ index | filterNome }}:
          <a :href="item" target="_blank"> Download </a>
        </v-col>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data: () => ({
    dialog: true,
    loading: false
  }),

  filters: {
    filterNome (value) {
      if (!value) return ''
      if (value == 'CARTA_FIANCA') return 'Carta Fiança'
      if (value == 'TERMOS_CONDICOES_LOCATARIO') return 'Termos e Condições do Locatário'
    }
  },
  computed: {
    ...mapGetters(['generatedDocuments'])
  },

  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>
