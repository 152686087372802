<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular
        color="primary"
        indeterminate
        size="90"
        width="6"
      ></v-progress-circular>
    </v-overlay>

    <v-card elevation="0">
      <v-subheader class="pl-0">
        <v-row>
          <v-col>
            <p>
              Home <v-icon color="black">mdi-chevron-right</v-icon> Lista de
              solicitações <v-icon color="black">mdi-chevron-right</v-icon>
              <b>Resumo da solicitação</b>
            </p>
          </v-col>
          <v-col align="right" v-if="isGSuser && inAnalysis">
            <v-menu bottom right offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn dark icon v-bind="attrs" v-on="on">
                  <v-icon color="black">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item>
                  <v-list-item-title>
                    <RequestCancel></RequestCancel>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-subheader>
      <h4 class="primary--text bold">Resumo da solicitação</h4>
      <br />
      <RequestInfosBar @situation-changed="refreshData"></RequestInfosBar>
      <br />

      <v-expansion-panels v-model="panel">
        <v-expansion-panel>
          <v-expansion-panel-header> Dados do imóvel </v-expansion-panel-header>
          <v-expansion-panel-content>
            <NewRequestRealty
              :title="false"
              :readonly="true"
            ></NewRequestRealty>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel class="mt-4">
          <v-expansion-panel-header> Observações </v-expansion-panel-header>
          <v-expansion-panel-content>
            <NewRequestObservation
              :title="false"
              :readonly="true"
            ></NewRequestObservation>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel class="mt-4">
          <v-expansion-panel-header> Partes </v-expansion-panel-header>
          <v-expansion-panel-content>
            <TenantTable
              :readonly="true"
              @reloadPendencies="init"
            ></TenantTable>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel v-if="isGSuser" class="mt-4">
          <v-expansion-panel-header>
            <v-row>
              <v-col lg="2" md="3" sm="6">
                <span>Análises</span>
              </v-col>
              <v-col lg="2" md="2" sm="5" class="pa-0 pt-3">
                <!--                 <v-select
                  no-data-text="Nenhum dado encontrado"
                  single-line
                  dense
                  class="ma-0 pa-0"
                  hide-details
                  v-model="selectedAnalysis"
                  :items="analysis"
                  label="Análise"
                  @change="init"
                ></v-select> -->
              </v-col>
              <v-spacer></v-spacer>
              <RequestNewAnalysis
                v-if="isGSuser && inAnalysis"
                @create="handleCreateAnalysis" @reload="listAnalysis"
              ></RequestNewAnalysis>
            </v-row>

            <template v-slot:actions>
              <v-icon> $expand </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <AnalysisCard
              @reload="listAnalysis"
              @getUrl="getReport"
            ></AnalysisCard>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="isGSuser" class="mt-4">
          <v-expansion-panel-header>
            <v-row>
              <v-col lg="2" md="3" sm="6">
                <span>Gerador de Documentos</span>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <span>Selecione os arquivos que deseja incluir:</span>
            <div>
              <v-checkbox
                v-model="archives"
                label="Carta Fiança"
                value="CARTA_FIANCA"
              ></v-checkbox>
            </div>
            <div>
              <v-checkbox
                v-model="archives"
                label="Termos e Condições do Locatário"
                value="TERMOS_CONDICOES_LOCATARIO"
              ></v-checkbox>
            </div>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn color="primary" @click="generateDocuments"> Gerar </v-btn>
            </v-col>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel class="mt-4">
          <v-expansion-panel-header>
            <v-row>
              <v-col lg="2" md="3" sm="6">
                <span class="error--text">Parecer e pendências</span>
              </v-col>
              <v-col lg="2" md="2" sm="5" class="pa-0 pt-3">
                <v-select
                  no-data-text="Nenhum dado encontrado"
                  single-line
                  dense
                  color="error"
                  class="ma-0 pa-0"
                  hide-details
                  v-model="selected"
                  :items="revisions"
                  label="Revisão"
                  @change="init"
                ></v-select>
              </v-col>
              <v-spacer></v-spacer>
              <RequestNewPendencie
                v-if="isGSuser && inAnalysis"
                @reloadList="init"
              ></RequestNewPendencie>
            </v-row>

            <template v-slot:actions>
              <v-icon color="error"> $expand </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <RequestPendencies @reloadPendencies="init"></RequestPendencies>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
    <generated-documents v-if="hasDocuments" @close="hasDocuments = false" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import RequestInfosBar from './InfosBar'
import TenantTable from '../Tenant/Table'
import NewRequestRealty from '../../components/NewRequest/Realty'
import NewRequestObservation from '../../components/NewRequest/Observation'
import RequestPendencies from '../../components/Request/Pendencies'
import RequestNewPendencie from './NewPendencie'
import RequestNewAnalysis from './NewAnalysis'
import AnalysisCard from './Analysis.vue'
import RequestCancel from './Cancel'
import GeneratedDocuments from './GeneratedDocuments.vue'

export default {
  name: 'RequestAbstract',
  components: {
    RequestInfosBar,
    TenantTable,
    NewRequestRealty,
    RequestPendencies,
    RequestNewPendencie,
    RequestCancel,
    NewRequestObservation,
    RequestNewAnalysis,
    AnalysisCard,
    GeneratedDocuments
  },
  data () {
    return {
      loading: false,
      selected: null,
      selectedAnalysis: null,
      hasDocuments: false,
      panel: 0,
      archives: []
    }
  },
  computed: {
    ...mapGetters([
      'isRealEstateUser',
      'isGSuser',
      'isRootUser',
      'inAnalysis',
      'requestRevision'
    ]),
    revision () {
      return this.requestRevision
    },
    revisions () {
      let i = this.revision
      const items = []
      while (i > 0) {
        items.push({
          value: i,
          text: i ? 'Revisão ' + i : ''
        })
        i--
      }
      items.push({
        value: 0,
        text: 'Todas as revisões'
      })
      return items
    },
    analysis () {
      const items = []
      items.push({
        value: 0,
        text: 'Todas as análises'
      })
      return items
    }
  },
  created () {
    this.init()
    this.listAnalysis()
  },
  methods: {
    ...mapActions([
      'getRequest',
      'createAnalysis',
      'getAnalysisList',
      'getAnalysisReport',
      'getDocumentGeneratedList'
    ]),
    init (revision = null) {
      if (this.$route.params.id) {
        this.loading = true
        const payload = {
          id: this.$route.params.id,
          revision: revision
        }
        this.getRequest(payload)
          .catch((err) => {
            this.$toastr.e(err.response.data.message)
          })
          .finally(() => {
            this.loading = false
            if (this.selected === null) {
              this.selected = this.revision
            }
            this.panel = 5
          })
      }
    },

    generateDocuments () {
      if (this.archives.length < 1) {
        return this.$toastr.e(
          'Selecione ao menos um documento para ser gerado'
        )
      } else {
        const payload = {
          solicitation_id: this.$route.params.id,
          reports: this.archives
        }
        this.getDocumentGeneratedList(payload).then(() => {
          this.hasDocuments = true
        })
      }
    },

    handleCreateAnalysis () {
      const payload = {
        solicitation_id: this.$route.params.id
      }
      this.loading = true
      this.createAnalysis(payload)
        .then(() => {
          this.$toastr.s('Análise Criada com sucesso')
          this.listAnalysis()
        })
        .catch((err) => {
          this.$toastr.e(err.response.data.message)
        })
        .finally(() => {
          this.loading = false
        })
    },

    listAnalysis () {
      if (this.$route.params.id) {
        const id = this.$route.params.id
        this.loading = true
        this.getAnalysisList(id).finally(() => {
          this.loading = false
        })
      }
    },

    getReport (item) {
      if (item.situacao_text == 'pendente') { return this.$toastr.e('A Análise encontra-se pendente') }
      if (this.$route.params.id) {
        this.loading = true
        this.getAnalysisReport(item.id)
          .then((resp) => {
            window.open(resp.data)
          })
          .catch((err) => {
            this.$toastr.e(err.response.data.message)
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    refreshData () {
      this.init()
      this.listAnalysis()
    }
  }
}
</script>

<style>
</style>
